<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-5">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        :label="$t('report_type')"
                                                        clearable
                                                        dense
                                                        outlined
                                                        single-line
                                                        item-text="id"
                                                        item-value="value"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-select :items="customYear"
                                              :label="$t('select_year')"
                                              dense
                                                    outlined
                                              v-model="serverParams.FromDate"
                                              prepend-inner-icon="event"
                                              ></v-select>

                                        <!--<v-menu
                                                v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="serverParams.FromDate"
                                                        :label="$t('from_date')"
                                                        clearable
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>-->
                                    </v-col>

                                    <!--   <v-col class="d-flex" cols="12" sm="3" v-if="!reportIsMonthWise">
                                           <v-menu
                                                   v-model="snackbar.menu2"

                                                   :nudge-right="40"
                                                   transition="scale-transition"
                                                   offset-y
                                                   min-width="290px"
                                           >
                                               <template v-slot:activator="{ on }">
                                                   <v-text-field
                                                           v-model="serverParams.ToDate"
                                                           label="To"
                                                           clearable
                                                           prepend-icon="event"
                                                           readonly
                                                           v-on="on"
                                                   ></v-text-field>
                                               </template>
                                               <v-date-picker v-model="serverParams.ToDate">

                                               </v-date-picker>
                                           </v-menu>
                                       </v-col>-->

                                    <v-col class="d-flex" cols="12" sm="3">

                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "TouristPoliceReport",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsGenderWise() {
                return this.serverParams.ReportType === "genderWise";
            },
            reportIsNationalityWise() {
                return this.serverParams.ReportType === "nationalityWise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.tourist_police'),
                        exact: true,
                        to: '/reportModules/reportTouristPolice'
                    },
                    {
                        text: this.$t('modules_name.monthly_yearly_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date +'-01-01';
                this.serverParams.ToDate = date + '-01-01';
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {
                    FromDate: this.serverParams.FromDate +'-01-01',
                };
                if (this.serverParams.ReportType == "yearWise") {
                    await axios({
                        url: 'Police/DownloadTouristPoliceByYearReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'TouristPoliceByYearReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType == "genderWise") {
                    await axios({
                        url: 'Police/DownlaodTouristPoliceByGenderReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'TouristPoliceByGenderReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else {
                    await axios({
                        url: 'Police/DownloadTouristPoliceByNationalityReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'TouristPoliceByNationalityReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'NationalParkFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let url = ''
                let param = {
                    FromDate: this.serverParams.FromDate +'-01-01',
                };
                if (this.reportIsGenderWise) {
                    url = 'Police/GetTouristPoliceByGenderReport'
                    this.assignColumn()
                } else if (this.reportIsNationalityWise) {
                    url = 'Police/GetTouristPoliceByNationalityReport'
                    this.assignColumn()
                } else {
                    url = 'Police/GetTouristPoliceByYearReport'
                    this.assignColumn()
                }
                axios.post(url, param)
                    .then(response => {
                        this.rows = response.data
                        console.log('this.rows', this.rows)
                        //this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            assignColumn() {
                if (this.reportIsGenderWise) {
                    this.columns = []
                    this.columns.push(
                        {
                            label: 'gender',
                            field: 'gender'
                        },
                        {
                            label: 'january',
                            field: 'january'
                        },
                        {
                            label: 'february',
                            field: 'february'
                        }, {
                        label: 'march',
                        field: 'march'
                    }, {
                        label: 'april',
                        field: 'april'
                    }, {
                        label: 'may',
                        field: 'may'
                    }, {
                        label: 'june',
                        field: 'june'
                    }, {
                        label: 'july',
                        field: 'july'
                    }, {
                        label: 'august',
                        field: 'august'
                    }, {
                        label: 'september',
                        field: 'september'
                    }, {
                        label: 'october',
                        field: 'october'
                    }, {
                        label: 'november',
                        field: 'november'
                    }, {
                        label: 'december',
                        field: 'december'
                        },
                        {
                            label: 'total',
                            field: 'total'
                        },
                    )
                } else if (this.reportIsNationalityWise) {
                    this.columns = []
                    this.columns.push(
                        {
                            label: 'nationality',
                            field: 'nationality'
                        },
                        {
                            label: 'january',
                            field: 'january'
                        },
                        {
                            label: 'february',
                            field: 'february'
                        }, {
                        label: 'march',
                        field: 'march'
                    }, {
                        label: 'april',
                        field: 'april'
                    }, {
                        label: 'may',
                        field: 'may'
                    }, {
                        label: 'june',
                        field: 'june'
                    }, {
                        label: 'july',
                        field: 'july'
                    }, {
                        label: 'august',
                        field: 'august'
                    }, {
                        label: 'september',
                        field: 'september'
                    }, {
                        label: 'october',
                        field: 'october'
                    }, {
                        label: 'november',
                        field: 'november'
                    }, {
                        label: 'december',
                        field: 'december'
                        },
                        {
                            label: 'total',
                            field: 'total'
                        },
                    )
                } else {
                    this.columns = []
                    this.columns.push(
                        {
                            label: 'table.incident',
                            field: 'incident'
                        },
                        {
                            label: 'january',
                            field: 'january'
                        },
                        {
                            label: 'february',
                            field: 'february'
                        }, {
                        label: 'march',
                        field: 'march'
                    }, {
                        label: 'april',
                        field: 'april'
                    }, {
                        label: 'may',
                        field: 'may'
                    }, {
                        label: 'june',
                        field: 'june'
                    }, {
                        label: 'july',
                        field: 'july'
                    }, {
                        label: 'august',
                        field: 'august'
                    }, {
                        label: 'september',
                        field: 'september'
                    }, {
                        label: 'october',
                        field: 'october'
                    }, {
                        label: 'november',
                        field: 'november'
                    }, {
                        label: 'december',
                        field: 'december'
                        },
                        {
                            label: 'total',
                            field: 'total'
                        },
                    )
                }
            }
        },
        mounted() {
            this.getNow()
            //this.loadItems()
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [],
                rows: [],
                ReportTypes: [
                    {
                        id: this.$t('year_wise'), value: "yearWise"
                    },
                    {
                        id: this.$t('gender_wise'), value: "genderWise"
                    },
                    {
                        id: this.$t('nationality_wise'), value: "nationalityWise"
                    },
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,

                }
            };
        },
    }
</script>

<style scoped>

</style>
